import React, { useEffect, useState } from "react"

import SEO from "../components/seo"
import Layout from "../components/Layout"
import Slider from "../components/Slider"
import SliderMobile from "../components/Slider_mobile"

import {IsMobileDevice} from '../utils'

const Home = () => {

  const [isMobile, setIsMobile] = useState(false)
  const [mobileLight, setMobileLight] = useState(false)

  useEffect(()=>{
    setIsMobile(IsMobileDevice())
    setMobileLight(IsMobileDevice())
  },[])

  useEffect(() => {
    window.dataLayer.push({
      brand: "Corona",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
          window.location.search.includes("?cmp=")
              ? window.location.search.split("?cmp=")[1].split("&")[0]
              : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Home",
      pageType: "Home",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.cervezacorona.com.ar/home",
      url_campaign: `${
          window.location.search.includes("utm_campaign=")
              ? window.location.search.split("utm_campaign=")[1]
              : ""
      }`,
      Step_number: "" ,
    })
  }, [])

  return (
    <Layout mobileLight={mobileLight} isMobile={isMobile}>
      <SEO title="Home"/>
      {isMobile ? <SliderMobile/> : <Slider/> }
    </Layout>
  )

}

export default Home

